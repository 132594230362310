import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './store';
import {apiInstance} from './services/api';

import * as constant from './utils/constants';

import * as user_actions from "./actions/user_actions";
import {history} from './history';
import isElectron from "is-electron";
import './utils/mousetrapGlobal';
import * as user_service from "./services/user_service";
import {FETCH_CURRENT_USER_ERROR, FETCH_CURRENT_USER_START, FETCH_CURRENT_USER_STOP} from "./actions/types";

import 'antd/dist/antd.min.css';
import './assets/css/antd.css';

// import './assets/css/antd.css';
// import './assets/font-awesome/css/all.min.css'
// import './assets/css/style.css';


const HOST_NAME = window.location.hostname;
const PORT = window.location.port;
// const ENVIRONMENT = 'local';
const ENVIRONMENT = 'development';
// const ENVIRONMENT = 'production';

if (ENVIRONMENT === 'local') {
  apiInstance.defaults.baseURL = constant.LOCAL_API_BASE_URL;
} else if (ENVIRONMENT === 'development') {
  apiInstance.defaults.baseURL = constant.REMOTE_DEV_API_BASE_URL;
} else if (ENVIRONMENT === 'production') {
  apiInstance.defaults.baseURL = constant.REMOTE_PROD_API_BASE_URL;
}

let token = localStorage.getItem('token');

if (token) {
  token = token.replace('"', '').replace('"', '');

  store.dispatch(user_actions.set_token(token));
  apiInstance.defaults.headers['Authorization'] = `Token ${token}`;

  store.dispatch({type: FETCH_CURRENT_USER_START});
  user_service.get_current_user().then(res => {
    let data = res.data;
    store.dispatch(user_actions.set_current_user(data));
    store.dispatch({type: FETCH_CURRENT_USER_STOP});
  }).catch(err => {
    if (err.response && err.response.status === 401 && err.response.data.message === 'Invalid token.') {
      store.dispatch(user_actions.user_logout());
    } else {
      store.dispatch({type: FETCH_CURRENT_USER_ERROR});
    }
  });
} else {
  history.push('/login');
}

// if (isElectron()) {
//   window.ipcRenderer.on('load-page', (event, arg) => {
//     history.push(arg);
//   });
//
//   window.ipcRenderer.on('logout', (event, arg) => {
//     if (arg === 'exit') {
//       if (localStorage.token) {
//         store.dispatch(user_actions.toggleLogoutModal(true));
//       } else {
//         window.ipcRenderer.send('closed');
//       }
//     } else {
//       store.dispatch(user_actions.toggleLogoutModal());
//     }
//   });
// }

ReactDOM.render(<Provider store={store}>
  <App />
</Provider>, document.getElementById('amahiMartApp'));

serviceWorker.unregister();
